import { useEthers, useEtherBalance } from "@usedapp/core";
import { formatEther } from "@ethersproject/units";
//import Identicon from "./Identicon";
import * as React from 'react';
import Typist from 'react-typist';

type Props = {
    handleOpenModal: any;
    classString: string;
};

export default function ConnectButton({ handleOpenModal, classString }: Props) {
    const { activateBrowserWallet, account } = useEthers();
    const etherBalance = useEtherBalance(account);

    function handleConnectWallet() {
        activateBrowserWallet();
    }
    function handleOpen() {
        handleOpenModal(true);
    }

    function getClass(walletType) {
        return classString + walletType;
    }
    return account ? (
        <div>

            <button className={getClass(" walletButtonTwo")} onClick={handleOpen}>
                    {account &&
                        `${account.slice(0, 6)}...${account.slice(
                            account.length - 4,
                            account.length
                        )}`}

            </button>
            </div>
    ) : (
            <div>
            <br/>
                <button className={getClass(" walletButton")} onClick={handleConnectWallet}>

                    █▀▀ █▀█ █▄░█ █▄░█ █▀▀ █▀▀ ▀█▀   ▀█▀ █▀█   █░█░█ ▄▀█ █░░ █░░ █▀▀ ▀█▀<br/>
                    █▄▄ █▄█ █░▀█ █░▀█ ██▄ █▄▄ ░█░   ░█░ █▄█   ▀▄▀▄▀ █▀█ █▄▄ █▄▄ ██▄ ░█░

                </button>
                </div>
    );
}

//<label>
//    {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} ETH
//</label>