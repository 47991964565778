import * as React from 'react';
import { useState, useEffect } from 'react';

import { useEthers, useEtherBalance } from "@usedapp/core";

import WalletStuff from './WalletStuff';

import DropDown from './MyDropDown';
import MintButton from "./MintButton";
import RemainingMint from "./RemainingMints";
import OwnedItems from "./ownedItems";
import addresses, { preListA, preListB, preListC } from "../addresses";
import { useBalance, useMaxPerWallet, useGetClaimed, useGetClaimState, useMaxSupply} from "../hooks";
import Typist from 'react-typist';
import { Prompt } from 'react-router'


export default function Mint(props) {

    //document.body.style.backgroundColor = '#343a40';


    //window.addEventListener('beforeunload', (event) => {
    //    event.returnValue = `Are you sure you want to leave?`;
    //});

    const { activateBrowserWallet, account } = useEthers();
    var windowIndex = -1;
    const maxItems = useMaxPerWallet();
    var itemPrice = 0.0;
    var windowName;
    var onAList = false;
    const claimOpen = useGetClaimState();
    //const claimOpen = true;

    const cursorState = {
        show: false,
        blink: true,
        element: '|',
        hideWhenDone: true,
        hideWhenDoneDelay: 1000,
    }

    const [modalIsOpen, setModalOpen] = useState(false);
    const [showWallet, setShowWallet] = useState(false);

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    function newState(state) {
        setModalOpen(state);
        setShowWallet(false);
    }

    useEffect(() => {
        async function makeRequest() {
            if (!onAList) {
                await delay(6000);
            }
            setShowWallet(true);
        }

        makeRequest();
    });

    var acc = "";

    if (account) {
        acc = account;
    }
    const ownedItems = useBalance(acc);
    const claimed = useGetClaimed(acc);
    var leftToClaim = maxItems - ownedItems;

    if (acc) {

        if (preListA.includes(acc)) {
            windowIndex = 0;
            itemPrice = 0;
            windowName = "List: Presale";
            onAList = true;
        }
    }

    const [itemNumber, setItemNumber] = useState(1);

    const totalSupply = Number(useMaxSupply());
    const itemName = "inkha";

    const [orderTotal, setOrderTotal] = useState(0.0);

    function updateItems(items: number) {
        if (items) {
            setItemNumber(items);
            updateTotal(items);
        }
    }

    function updateTotal(items: number) {

        let itemsTotal: number = Math.round(items * 1000 * itemPrice) / 1000;
        setOrderTotal(Math.round(1000 * (itemsTotal)) / 1000);
    }

    function getBalance() {
        if (ownedItems)
            return "You have claimed: " + ownedItems.toString() + " inkhas";
        else
            return "";
    }

    function getWallet() {

        if (showWallet) {
            return (
                <WalletStuff className="mintTitleSub" classNme="screenAccountModal" setOpen={newState} isOpen={modalIsOpen} classString="relativeWallet" />
                );
        }
    }

    function getSubTitle() {

        if (!showWallet) {

            if (!claimOpen) {

                return (
                    <div className="mintTitleSub">
                        
                    █░░ █▀█ ▄▀█ █▀▄ █ █▄░█ █▀▀ ░ ░ ░<br />
                    █▄▄ █▄█ █▀█ █▄▀ █ █░▀█ █▄█ ▄ ▄ ▄<br />

                    </div>
                );

            }

            else{

                return (
                    <div className="mintTitleSub">

                    █▀▄▀█ █ █▄░█ ▀█▀   █▀▀ █▀█ █▀▄▀█ █ █▄░█ █▀▀   █▀ █▀█ █▀█ █▄░█       <br />
                    █░▀░█ █ █░▀█ ░█░   █▄▄ █▄█ █░▀░█ █ █░▀█ █▄█   ▄█ █▄█ █▄█ █░▀█ ▄ ▄ ▄ <br />

                    </div>
                );

            }
        }
    }



    return (modalIsOpen ? (
        <div className="centralMintPage crt">

            <div className="scanline"></div>

            <img className="mintPageLogo" src="images/whooshinkhanew.png" alt="image of inkha logo" />


            <div className="mintTitleTop">

                █ █▄░█ █▄▀ █░█ ▄▀█   █▄░█ █▀▀ ▀█▀ <br />
                █ █░▀█ █░█ █▀█ █▀█   █░▀█ █▀░ ░█░ <br />

            </div>

            {getWallet()}

            </div>
    ) :

        acc && onAList && claimOpen ? (
            <div className="centralMintPage crt">

                <div className="scanline"></div>

                <img className="mintPageLogo" src="images/whooshinkhanew.png" alt="image of inkha logo" />

                <div className="mintTitleTop">

                    █ █▄░█ █▄▀ █░█ ▄▀█   █▄░█ █▀▀ ▀█▀ <br />
                    █ █░▀█ █░█ █▀█ █▀█   █░▀█ █▀░ ░█░ <br />

                </div>

                <RemainingMint redeemWindow={windowIndex} itemName={itemName} totalSupply={totalSupply} />
                <div className="windowName">{windowName}</div>



                <MintButton orderValue={orderTotal} itemNumber={itemNumber} windowIndex={windowIndex} mintPrice={itemPrice} />

                <div className="balance">
                    {getBalance()}
                </div>

                <DropDown leftToClaim={leftToClaim} update={updateItems.bind(Mint)} />

                {getWallet()}
        

            </div>
        ) :
            (
                <div className="centralMintPage crt">

                    <div className="scanline"></div>

                    <img className="mintPageLogo" src="images/whooshinkhanew.png" alt="image of inkha logo" />
                    <Typist cursor={cursorState} avgTypingDelay={60}>

                    <div className="mintTitle">
                        █ █▄░█ █▄▀ █░█ ▄▀█   █▄░█ █▀▀ ▀█▀ <br />
                        █ █░▀█ █░█ █▀█ █▀█   █░▀█ █▀░ ░█░ <br />
                    </div>
                    </Typist>


                    {getSubTitle()}

                    {getWallet()}


                </div>

            ));
}

//<div className="mintTitleSub">
