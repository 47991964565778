import { ethers } from "ethers";
import { contractAddress } from "../contracts"
import { useContractCall, useContractFunction } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";

import contractAbi from "../abi/projectContract.json";

const contractInterface = new ethers.utils.Interface(contractAbi);
const redeemContract = new Contract(contractAddress, contractInterface);

//const itemTokenId = 0;

export function useCount() {
    const [count]: any = useContractCall({
        abi: contractInterface,
        address: contractAddress,
        method: "totalSupply",
        args: [],
    }) ?? [];
    return count;
}

export function useGetTokenIDS(address: string) {
    const [bal]: any = useContractCall({
        abi: contractInterface,
        address: contractAddress,
        method: "getTokenIDS",
        args: [address],
    }) ?? [];
    return bal;
}

export function useBalance(address: string) {
    const [bal]: any = useContractCall({
        abi: contractInterface,
        address: contractAddress,
        method: "balanceOf",
        args: [address],
    }) ?? [];
    return bal;
}

export function useMaxPerTx(address: string) {
    const [max]: any = useContractCall({
        abi: contractInterface,
        address: contractAddress,
        method: "getMaxPerTx",
        args: [],
    }) ?? [];
    return max;
}

export function useMaxPerWallet() {
    const [max]: any = useContractCall({
        abi: contractInterface,
        address: contractAddress,
        method: "getMaxPerWallet",
        args: [],
    }) ?? [];
    return max;
}

export function useGetClaimState() {
    const [state]: any = useContractCall({
        abi: contractInterface,
        address: contractAddress,
        method: "getClaimState",
        args: [],
    }) ?? [];
    return state;
}

export function useGetClaimed(address: string) {
    const [state]: any = useContractCall({
        abi: contractInterface,
        address: contractAddress,
        method: "getClaimed",
        args: [address],
    }) ?? [];
    return state;
}

export function useMaxSupply() {
    const [supply]: any = useContractCall({
        abi: contractInterface,
        address: contractAddress,
        method: "getMaxSupply",
        args: [],
    }) ?? [];
    return supply;
}

//export function getOwnedTokens(address: string) {
//    const [tokens]: any = useContractCall({
//        abi: contractInterface,
//        address: contractAddress,
//        method: "getTokenIDS",
//        args: [address],
//    }) ?? [];
//    return tokens;
//}

export function useContractMethod(methodName: string) {

    const { state, send } = useContractFunction(redeemContract, methodName, {});
    return { state, send };
}