import * as React from 'react';
import { Component } from 'react';

export default class App extends Component {
    render() {
        return (
            <div className="centralMainPage">
                <div className="blurb">
                    <h2>plastic inkha</h2>

                    <p>inkha ltd was formed in june 2004 in order to develop a version of inkha for education</p>

                    <p>a prototype was created using plastic components and low-cost servo motors</p>

                    <p>it was planned that the components would be provided in kit form along with instructions and teaching materials</p>

                    <p>when exhibited the prototype was found to attract similar levels of attention to the metal version and was robust in use</p>
                </div>
            </div >

        )
    }
}

