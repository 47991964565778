//inc fatlion account

export const preListA = [
    "0xB7339748878317BD87dF00FFd90d500a38C5c57F",
    "0x79Bb4548b5a24fb1CB29775D36073315D5e0F4d6",
    "0xBEB79F5B928F7357914E728636d2630aCfcBbDBb",
    "0xa30C725B985e0e574ad39FD72E96953C14371C5E",
    "0x1D934697Eb2351BCCf0EDD2b4187ce4F3AfE67eB",
    "0x8d0B7191Fdb08d65A42001E1928c05d147FBfD5D",
    "0x325871d0EF3F27c4f837c4714aE5C2ba5B543425",
    "0xb7e2ABd1952A0c93e14F9e87c42baD7c67d89f31",
    "0xb442d26D2b0848E4782cfd7553EE75497f897CCA",
    "0xB327C369750D45FAe30b836d36D1692787a255E0",
    "0x3F243efc168B110b2764f48A9e2cc1AFf5551Aba",
    "0xf5bfc78f72e0cd0BbB2BA3Fc40a450578Be3498d",
    "0x1C59810C08b87c5812A05221DbC954Ea7546f402",
    "0x8C49d2afaD5213C313712787b5B7c0865C9345b7",
    "0x5A4DbCb6A544888aAF739d8E4431cF9D0ee81300",
    "0x333975fa6D917B8f89AFcDaeF9BaD0A2C66fc66A",
    "0xE3b2e0086D5A9A7f8Fb7b48107b6bDeFC97C8AB2",
    "0xB384A7a9c57d5EA414AB4B6cCAc9c2A5DF360e28",
    "0x3Eef5d3C048eC430E5937103949BA7E00f2e4FD2",
    "0xB17296127047810823be1AB0311E8646328D3947",
    "0xf396226FaC1a50992F98458042b189A0690fE2ba",
    "0x874A80Dc85c4D5e060b0C93B51Ca995fE8c243bB",
    "0xB89f17Dd3772EFa4cf32785c3ad8c73a38A82409",
    "0x87416A3BF96ddd2355e08969a5bc9756E916d1e6",
    "0x32DD4422F4BbC4364Ce184C487f3744BD5B5Eba1",
    "0x45909B8ACc1ace1Ba9910EA7023EEDa492ba058c",
    "0x911133ed843DF23f9B8E5aB51933AA6248f27427",
    "0x14F69c8c334C4C6Ea526C58Ae94b1431826aCE94",
    "0xa77E74548B81fF25b378017E80E1838eE1d78fb5",
    "0x68bD8e728A00798f1974c96D2b1C03F548948C42",
    "0x0B10f3938442e4737F34548e4E932Ec773fE702C",
    "0x4b085Ae880B3a161EE763aC3f56a95c1fACdBdE4",
    "0x65A323C2b936449AAaf62F41a62bb7219139D49F",
    "0x3bd62695ff1B6Af4F0998E775B3558b64E6779C9",
    "0x85b826B5eB230D03Ce1BB41DED646909bF0c3F4E",
    "0xC789b7B6Dfb3C72E7c98a2A2892d412A1852FA31",
    "0xFE8201Ce69A82DEa64e633eC37b0b719eD316402",
    "0xE8a13a276399ec237D8E2eEe8ECbA1CB9E9Ac990",
    "0x378dcff8e6be1778b04F5712d686517Bb6a01927",
    "0xd38c7F08B7fb62d24F371ff354d66fb74f8aFB34",
    "0xF3F04181b89a8EBbdA2d950419AaF26aDd709D5f",
    "0xf15ab62d2c2CaC87A11cf73B2dee426dB1663807",
    "0xEA186fd995d2164bA84be0D41d649a986c9d3c4e",
    "0x58CD21074A9Db3fd87366e0D3eB9FB300dFEd120",
    "0xe8ce8248ad8b87d56163c24d21772785f811544f",
    "0x2219828F21142B99FC3D24340A91a4cEf8996353",
    "0x1BFa36EA533bAE7fa8EB8Dc518c80BD91335e936",
    "0x8572f08DdeCC9e13b24A0fda86c1dBb874caa7ba"
]

// inc test account 1
export const preListB = [
    "0x79Bb4548b5a24fb1CB29775D36073315D5e0F4d6",
]

// inc test account 2
export const preListC = [
    "0x04e321d658358a3b576b9bd822f5Cea9175Af4Af",
]

export default {
    preListA, preListB, preListC,
}

