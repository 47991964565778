import * as React from 'react';
import { Component } from 'react';
import { Link} from 'react-router-dom';

export default class App extends Component {
    render() {
        return (
            <div className="centralMainPage">

                <div className="top">
                    <h2>plastic inkha videos</h2>
                </div>
                <div className="blurb">
                    <table>
                        <tbody>
                            <tr>
                                <td width="50%"><a href="video/pinkha2.mp4"><img src="jpegs/pinkhavideo2.jpg" alt="click to see the video"  />
                                </a><br /></td>
                                <td width="50%"><a href="video/pinkha1.mp4"><img src="jpegs/pinkhavideo1.jpg" alt="click to see the video"  />
                                </a><br /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        )
    }
}

