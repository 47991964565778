import { useState } from "react";
import { useEffect } from 'react'
import * as React from 'react';
import { useEthers, useEtherBalance } from "@usedapp/core";
import { utils } from "ethers";
import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { preListA, preListB, preListC } from "../addresses";
import { contractAddress } from "../contracts"
import contractAbi from "../abi/projectContract.json";

import { useCount, useContractMethod } from "../hooks";

export default function Count(props) {
    //const count = useEditionsCount(props.windowIndex);
    const count = useCount();

    const [buttonText, setButtonText] = useState("Claim");
    const [buttonStateDisabled, setButtonDisabledState] = useState(false);
    const { activateBrowserWallet, account } = useEthers();
    var acc = "";
    if (account) {
        acc = account;
    }
    //const balance = useOrbsBalance(acc);

    const { state: setOrbMintState, send: mintItInkha } = useContractMethod("mintInkha");
    const { MerkleTree } = require('merkletreejs')
    const keccak256 = require('keccak256')

    const byPassMerkle = true;

    var hexProof;

    var leaves;

    if (props.windowIndex == 0) {
        leaves = preListA.map(x => keccak256(x));
    }

    else if (props.windowIndex == 1) {
        leaves = preListB.map(x => keccak256(x));
    }

    else if (props.windowIndex == 2) {
        leaves = preListC.map(x => keccak256(x));
    }
        useEffect(() => {

            if (setOrbMintState.status == 'Exception' || setOrbMintState.status == 'Success') {
                resetMint()
            }

            if (setOrbMintState.status == 'Mining') {

                setButtonText("Claiming...");
                setButtonDisabledState(true);
            }
        }, [setOrbMintState])

    

    function resetMint() {
        setButtonText("Claim");
        setButtonDisabledState(false);
    }

    async function estimateMint(argOne, argTwo) {
        if (window.ethereum != null) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            let signer0 = provider.getSigner();
            const orbContractInterface = new ethers.utils.Interface(contractAbi);
            const inkhaContract = new Contract(contractAddress, orbContractInterface, signer0);

            return await inkhaContract.estimateGas.mintInkha(argOne, argTwo).catch(err => displayError(err));
        }
        else {
            return await null;
        }
    }

    //function closeError() {

    //}

    function displayError(err) {
        console.log(err)
        setButtonText("Error");
    }

    function getError() {
        //console.log(setOrbMintState);
        if (setOrbMintState.status != "None") {
            return (
                <div className="errorMessage">
                    {setOrbMintState ? (setOrbMintState.errorMessage) : (<div />
                    )}
                </div>
            );
        }
    }

    async function handleMint() {
        if (account) {
            
            if (props.amount < 1) {
                //console.log("No Items chosen");
                return;
            }

            const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
            const root = tree.getRoot().toString('hex')
            const rootHex = tree.getHexRoot();
            var leaf = keccak256(account);
            //console.log(root);
            const proof = tree.getProof(leaf)

            hexProof = tree.getHexProof(leaf)
            //console.log(hexProof);

  
            var gas = await estimateMint(props.itemNumber, hexProof);

            if (gas) {
                let adjustedGas = Math.round(parseInt(gas._hex, 16) * 1.3)
                mintItInkha(props.itemNumber, hexProof, {
                    gasLimit: adjustedGas
                });
            }
        }
    }

    return account ? (
        <div className="mintSection">
            <button onClick={handleMint} disabled={buttonStateDisabled}>
                {buttonText}
            </button>
            <br/>
            <br/>
            {getError()}
        </div>

    ) :
        (<div />
        );
}