import * as React from 'react';
import { connect } from 'react-redux';
import YoutubeEmbed from "./YoutubeEmbedded";
import Hero from './hero';
import HeroFooter from './heroFooter';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const Home = () => (
    <div className ="centralMainPage">
        <header>
            <title>Inkha-Home</title>
        </header>

        <table className="homePageTable">
            <tbody>
            <tr>
                <td ><NavLink tag={Link} to="/TextInkha"><img className="homeImage floatRight" src="jpegs/SUN2.JPG" alt="click for information about inkha" /></NavLink></td>
                <td ><NavLink tag={Link} to="/TextOhbot"><img className="homeImage floatLeft" src="jpegs/rohbot.jpg"  alt="click for information about ohbot" /></NavLink></td>

            </tr>
            <tr>
                <td ><NavLink tag={Link} to="/TextSteve"><img className="homeImage floatRight" src="jpegs/rsteve.JPG" alt="click for information about steve" /></NavLink></td>
                <td ><NavLink tag={Link} to="/TextPlasticInkha"><img className="homeImage floatLeft" src="jpegs/rpinkha.jpg" alt="click for information about plastic inkha" /></NavLink></td>
                </tr>
            </tbody>
        </table>

    </div>
);

export default connect()(Home);
