//import { useState } from "react";
import * as React from 'react';
//import { useEthers, useEtherBalance } from "@usedapp/core";
import { utils } from "ethers";

import { useCount } from "../hooks";


export default function Count(props) {

    interface MintProps {
        totalSupply: number
        itemName: string
        mintPrice:number
    }

    const count = useCount();
    return (
        <div className="orderTotal">
            <label>
                {count ? props.totalSupply - count.toNumber() : "0"} /{props.totalSupply} {props.itemName}s left.
            </label>
        </div>

    );
}