import * as React from 'react';
import { Collapse, Container, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap'

import './NavMenu.css';
import { MMButton } from './MMButton';
export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        const menuRemove = () => {
            let nav = document.getElementsByClassName("nav-item")
            if (nav[0].classList.contains("show")) {
                nav[0].classList.remove("show")
            } 
        }

        return (
                        <Navbar bg="dark" variant="dark" expand="lg">
                            <Container>
                                <Navbar.Brand> <NavLink tag={Link} to="/"><img src="images/home.gif" alt="home" className="menuIcon" /> </NavLink> </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <NavDropdown title="Inkha" id="basic-nav-dropdown">
                                        <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/TextInkha">Text</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/ImagesInkha">Images</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/VideoInkha">Video</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/TechInkha">Tech</NavLink></NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            {/*<NavDropdown.Item href="nft">NFT</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/Mint">Mint</NavLink></NavDropdown.Item>*/}
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/Player">Player</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/HelpInkha">Help</NavLink></NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="Steve" id="basic-nav-dropdown">
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/TextSteve">Text</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/ImagesSteve">Images</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/VideoSteve">Video</NavLink></NavDropdown.Item>
                                        </NavDropdown>
                                        <NavDropdown title="Plastic" id="basic-nav-dropdown">
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/TextPlasticInkha">Text</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/ImagesPlastic">Images</NavLink></NavDropdown.Item>
                                <NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/VideoPlastic">Video</NavLink></NavDropdown.Item>
                                        </NavDropdown>
                                        <NavLink tag={Link} to="/TextOhbot">Ohbot</NavLink>
                                        <NavLink tag={Link} to="/TextAbout">About</NavLink>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });


    }
}

//<NavDropdown.Item><NavLink className="nav-link-sub" tag={Link} to="/player">player</NavLink></NavDropdown.Item>
