import * as React from 'react';
import { Component } from 'react';

import forceNumber from 'force-number';
//import { RangeStepInput } from 'react-range-step-input';

//<div className="centralText">
//    <p>{this.state.value} @ {this.props.price} Ξ</p>
//</div>

interface DropDownProps {
    leftToClaim: number
    update: Function
}

interface DropDownState {
    value: number
}

class MyDropDown extends Component<DropDownProps, DropDownState> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: 1
        };
        this.onChange = this.onChange.bind(this);
    }
    render() {
        return (
            this.getOptions(this.props.leftToClaim)
        );
    };
    onChange(e: any) {
        if (e) {
            var newVal: number = forceNumber(e.target.value);
            this.setState({ value: newVal });
            this.props.update(newVal);
        }
    }

    getOptions(index) {
        var indexes: number[] = []

        for (let x:number = 1; x <= index; x++) {
            indexes.push(x);
        }

            return (
                <div className="sliderDiv">
                    <select name="toMint" id="mintNoDD" value={this.state.value} onChange={this.onChange.bind(this)}>
                {
                    indexes.map((i) => (
                        <option value={i.toString()} key={i.toString()}>{i}</option>
                    ))
                        }
                    </select>
                </div>

            )
    }

}
export default MyDropDown;