import * as React from 'react';
import { Component } from 'react';

export default class App extends Component {
    render() {
        return (
            <div className="centralMainPage">
                <div className="blurb">

                <h2>inkha</h2>
                <p>
                    inkha is a reactive robotic head <br />
                    it tracks moving objects using cameras inside its eyes<br />
                    it issues verbal statements based on visual, sensory, environmental and touch-screen input<br />
                    it demonstrates various behaviours including fright, nonchalance and boredom<br />
                    its movement is lifelike
                </p>
                <br/>
                <p>
                    inkha was established at king's college london in 2002 by matthew walker and peter s longyear.&nbsp;
                    the project was supervised by dr kaspar althoefer from the department of mechanical engineering
                </p>
                <br/>
                <p>
                    inkha was installed as a permanent robotic receptionist at king's college london
                    between december 2003 and october 2014 where it ran 9am to 5pm monday to friday.&nbsp; the installation was
                    initiated by dr mark miodownik from the department of mechanical engineering
                </p>
                <br/>
                <p>
                    inkha has appeared on television programs and in newspaper articles all over the world.&nbsp; there have been physical appearances at
                    science events around the uk and in the 2005 faraday lectures
                </p>
                <br/>
                <p>
                    inkha has visited japan and hong kong
                </p>
                <br/>
                <p>
                    inkha became part of the robots' exhibition at london's science museum in 2017 and will be on tour from September 2017 until 2022
                </p>
                <br/>
                <p>
                    inkha became the world's first programmable nft in February 2022
                </p>
                </div >
            </div>
        )
    }
}

