import * as React from 'react';
import { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

export default class App extends Component {
    state = { isOpen: false, bigName: '' };

    handleShowDialog = (fname) => {
        if (fname == '')
            this.setState({ isOpen: false });
        else {
            this.setState({ isOpen: !this.state.isOpen });
            this.setState({ bigName: fname });
        }
        // console.log(fname);
    }

    render() {
        return (
            <div className="centralMainPage">
                {this.state.isOpen && (
                    <div className="backButton">
                        <img src="images/back.png" onClick={() => this.handleShowDialog('')} height="20px"></img>
                    </div>
                )}
                <div className="top">
                    <h2>inkha images</h2>
                </div>
                {this.state.isOpen && (
                    <div className="blurb">
                    <img
                        className="image"
                        src={this.state.bigName}
                        onClick={this.handleShowDialog}
                        title="click to close"
                    />
                    </div>
                )}
                {!this.state.isOpen && (
                    <div className="blurb">
                    <table className="imageTable">
                        <tbody>
                            <tr>
                                <td width="50%"><img src="jpegs/rscimus.jpg" onClick={() => this.handleShowDialog('jpegs/scimus.jpg')} title="click for a larger view" />
                                <br />science museum</td>
                                <td width="50%"><img src="jpegs/rcatalogue.jpg" onClick={() => this.handleShowDialog('jpegs/catalogue.jpg')} title="click for a larger view" />
                                <br />science museum catalogue</td>
                            </tr>
                            <tr>
                                <td width="50%"><img src="jpegs/rscimusdisplay1.jpg" onClick={() => this.handleShowDialog('jpegs/scimusdisplay1.jpg')} title="click for a larger view" />
                                <br />science museum</td>
                                <td width="50%"><img src="jpegs/rscimusdisplay2.jpg" onClick={() => this.handleShowDialog('jpegs/scimusdisplay2.jpg')} title="click for a larger view" />
                                <br />science museum</td>
                            </tr>
                            <tr>
                                <td width="50%"><img src="jpegs/rscimushk.jpg" onClick={() => this.handleShowDialog('jpegs/scimushk.jpg')} title="click for a larger view" />
                                <br />hong kong science museum</td>
                                <td width="50%"><img src="jpegs/rpassmach.jpg" onClick={() => this.handleShowDialog('jpegs/passmach.jpg')} title="click for a larger view" />
                                <br />passionate machines conference</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td width="100%"><img src="jpegs/rguardian.jpg" onClick={() => this.handleShowDialog('jpegs/guardian.jpg')} title="click for a larger view" />
                                <br />guardian film</td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td width="50%"><img src="jpegs/rbp1.jpg" onClick={() => this.handleShowDialog('jpegs/bp1.jpg')} title="click for a larger view" />
                                <br />blue peter</td>
                                <td width="50%"><img src="jpegs/rbp2.jpg" onClick={() => this.handleShowDialog('jpegs/bp2.jpg')} title="click for a larger view" />
                                <br />blue peter</td>
                            </tr>
                            <tr>
                                    <td width="50%"><img src="jpegs/rplinth2g.jpg" onClick={() => this.handleShowDialog('jpegs/plinth2g.jpg')} title="click for a larger view" />
                                <br />kings</td>
                                    <td width="50%"><img src="jpegs/rinkha2close3.jpg" onClick={() => this.handleShowDialog('jpegs/inkha2close3.jpg')} title="click for a larger view" />
                                <br />kings</td>
                            </tr>
                            <tr>
                                    <td width="50%"><img src="jpegs/rInkhaBirthday.jpg" onClick={() => this.handleShowDialog('jpegs/InkhaBirthday.jpg')} title="click for a larger view" />
                                <br />kings 10th birthday party</td>
                                    <td width="50%"><img src="jpegs/rCakeStandInsta.jpg" onClick={() => this.handleShowDialog('jpegs/CakeStandInsta.jpg')} title="click for a larger view" />
                                <br />kings 10th birthday party</td>
                            </tr>
                        <tr>
                                    <td width="50%"><img src="jpegs/rdana14.jpg" onClick={() => this.handleShowDialog('jpegs/dana14.jpg')} title="click for a larger view"  />
                            <br />dana centre</td>
                                    <td width="50%"><img src="jpegs/rdana16.jpg" onClick={() => this.handleShowDialog('jpegs/dana16.jpg')} title="click for a larger view"  />
                            <br />dana centre</td>
                        </tr>
                        <tr>
                                    <td width="50%"><img src="jpegs/rtw2g.jpg" onClick={() => this.handleShowDialog('jpegs/tw2g.jpg')} title="click for a larger view"  />
                            <br />tomorrow's world roadshow</td>
                                    <td width="50%"><img src="jpegs/rcrowdback3g.jpg" onClick={() => this.handleShowDialog('jpegs/crowdback3g.jpg')} title="click for a larger view"  />
                            <br />tomorrow's world roadshow</td>
                        </tr>
                        <tr>
                                    <td width="50%"><img src="jpegs/rhara.jpg" onClick={() => this.handleShowDialog('jpegs/hara.jpg')} title="click for a larger view"  />
                            <br />hara labs tokyo</td>
                                    <td width="50%"><img src="jpegs/rcheltenham.jpg" onClick={() => this.handleShowDialog('jpegs/cheltenham.jpg')} title="click for a larger view"  />
                            <br />cheltenham science festival</td>
                        </tr>
                        <tr>
                                    <td width="50%"><img src="jpegs/rinkhadublin2.jpg" onClick={() => this.handleShowDialog('jpegs/inkhadublin2.jpg')} title="click for a larger view"  />
                            <br />artbots dublin</td>
                                    <td width="50%"><img src="jpegs/rinkhadublin4.jpg" onClick={() => this.handleShowDialog('jpegs/inkhadublin4.jpg')} title="click for a larger view"  />
                            <br />artbots dublin</td>
                        </tr>
                        <tr>
                                    <td width="50%"><img src="jpegs/rinkha2crop.jpg" onClick={() => this.handleShowDialog('jpegs/inkha2crop.jpg')} title="inkha2crop.jpg (144817 bytes)"  /></td>
                                    <td width="50%"><img src="jpegs/rlids.jpg" onClick={() => this.handleShowDialog('jpegs/lids.jpg')} title="lids.jpg (46302 bytes)"  /></td>
                        </tr>
                        <tr>
                                    <td width="50%"><img src="jpegs/rsun1.jpg" onClick={() => this.handleShowDialog('jpegs/SUN1.jpg')} title="SUN1.JPG (41555 bytes)"  /></td>
                                    <td width="50%"><img src="jpegs/rscary.jpg" onClick={() => this.handleShowDialog('jpegs/scary.jpg')} title="scary.jpg (50522 bytes)"  /></td>
                        </tr>
                        <tr>
                                    <td width="50%"><img src="jpegs/rlatex.jpg" onClick={() => this.handleShowDialog('jpegs/latex.jpg')} title="latex.jpg (37045 bytes)"  /></td>
                                    <td width="50%"><img src="jpegs/rsun2.jpg" onClick={() => this.handleShowDialog('jpegs/SUN2.jpg')} title="SUN2.JPG (33151 bytes)"  /></td>
                        </tr>
                        <tr>
                                    <td width="50%"><img src="jpegs/rinkhajapan.jpg" onClick={() => this.handleShowDialog('jpegs/inkhajapan.jpg')} title="inkhajapan.jpg (42744 bytes)"  /></td>
                                    <td width="50%"><img src="jpegs/rinkhac.jpg" onClick={() => this.handleShowDialog('jpegs/INKHAC.jpg')} title="INKHAC.JPG (49528 bytes)"  /></td>
                        </tr>
                        <tr>
                                    <td width="50%"><img src="jpegs/rlab.jpg" onClick={() => this.handleShowDialog('jpegs/LAB.jpg')} title="LAB.JPG (46360 bytes)"  /></td>
                                    <td width="50%"><img src="jpegs/reyesjaw.jpg" onClick={() => this.handleShowDialog('jpegs/EYESJAW.jpg')} title="EYESJAW.JPG (49074 bytes)"  /></td>
                        </tr>
                        </tbody>
                        </table>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="100%"><img src="jpegs/4inkhas.jpg" title="4inkhas.jpg (100352 bytes)" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    )}
                </div>
        )
    }
}

//<ImageGallery items={images} />