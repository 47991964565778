import * as React from 'react';
import { Component } from 'react';

export default class App extends Component {
    render() {
        return (
            <div className="centralMainPage">
                <div className="blurb">
                    <h2>inkha tech</h2>
                    <div className="wash" style={{
                        backgroundImage: `url("images/TECH.GIF")`
                    }}>
                        <ul>
                            <li><a href="TechInkha#Software">software</a></li>
                            <li><a href="TechInkha#Hardware">hardware</a></li>
                            <li><a href="TechInkha#Experiments">experiments</a></li>
                            <li><a href="TechInkha#DataSheets">data Sheets</a></li>
                        </ul>
                    </div >
                    <hr id="Software" />
                    <h3>inkha software</h3>
                    <div className="wash" style={{
                        backgroundImage: `url("images/textswbg.gif")`
                    }}>
                        <ul>
                            <li><a href="TechInkha#SoftwareConsiderations">considerations</a></li>
                            <li><a href="TechInkha#SoftwareVision">vision</a></li>
                            <li><a href="TechInkha#SoftwareControl">control</a></li>
                            <li><a href="TechInkha#SoftwareLogging">logging</a></li>
                            <li><a href="TechInkha#SoftwareSpeech">speech</a></li>
                            <li><a href="TechInkha#SoftwareModules">modules</a></li>
                        </ul>
                    </div>
                    <h4 id="SoftwareConsiderations">inkha software considerations</h4>
                    <div className="techbody">
                        <p><b>environment</b></p>

                        <p>visual basic version 6
                            running under microsoft windows xp operating system was chosen as the target development
                            environment as it gave straightforward access to speech, graphics, serial interfacing and
                            camera interfacing</p>

                        <p>windows is not a real-time
                            operating system - as it does not support pre-emptive multi-tasking or inter-task
                            synchronisation- but the real time performance is sufficient for the needs of this project</p>

                        <p><b>concurrency</b></p>

                        <p>the interface executable module
                            and all of the lower level modules that it calls run synchronously with the following
                            exceptions:</p>

                        <ul>
                            <li>the speech module calls into a
                                microsoft library function which uses a separate process to produce speech asynchronously<br />
                                    the advantage of this is that processing continues whilst speech is produced<br />
                                    the disadvantage is that callback functions have to be implemented in the calling module
                                to move the mouth of the simulation during speech
                            </li>
                            <li>the seetron dll which takes care of
                                    low level communication with the servo motors when using the ssc servo controller uses a
                                    separate process to send commands to the servo motors asynchronously<br />
                                        this reduces the delay in instructing the motors but it has the disadvantage that any
                                        position instruction that it is buffering is overwritten if a new instruction is issued
                                        before it is cleared from the queue<br />
                                        the result is that movement is likely to miss intermediate steps on a slow processor<br />
                                            a full description of how the seetron dll works can be found on the seetron web site (see
                                links)
                            </li>
                            <li>the sensor module is called from
                                the interface module on a separate timer <br />
                                this means that camera sampling occurs on a timer so it runs concurrently with the control
                                system which is desired behaviour as it is important for the sensor module to continue
                                monitoring the cameras irrespective of what the control system is doing so that the
                                previous image, which is compared to the current image to find motion, does not become too
                                old<br />
                            </li>
                        </ul>

                        <p><b>portability</b></p>

                        <p>the system runs on a pc with the
                            following minimum specification:</p>

                        <ul>
                            <li>1ghz processor </li>
                            <li>256 mb memory </li>
                            <li>1 usb port </li>
                            <li>1 serial port </li>
                            <li>sound card</li>
                        </ul>

                        <p>the periodic aspects of the system
                            are independent of processor speed</p>

                        <p><b>integration</b></p>

                        <p>the format and scope of the
                            vectors sent from the sensor module to the interface module are as follows:</p>

                        <table>
                            <tr>
                                <td>N</td>
                                <td>target is in the north sector</td>
                            </tr>
                            <tr>
                                <td>NE</td>
                                <td>target is in the north east sector</td>
                            </tr>
                            <tr>
                                <td>E</td>
                                <td>target is in the east sector</td>
                            </tr>
                            <tr>
                                <td>SE</td>
                                <td>target is in the south east sector</td>
                            </tr>
                            <tr>
                                <td>S</td>
                                <td>target
                                    is in the south section</td>
                            </tr>
                            <tr>
                                <td>SW</td>
                                <td>target
                                    is in the south west sector</td>
                            </tr>
                            <tr>
                                <td>W</td>
                                <td>target
                                    is in the west sector</td>
                            </tr>
                            <tr>
                                <td>NW</td>
                                <td>target
                                    is in the north west sector</td>
                            </tr>
                            <tr>
                                <td>C</td>
                                <td>target
                                    is in the centre section</td>
                            </tr>
                            <tr>
                                <td>F</td>
                                <td>movement
                                    level is above fright threshold</td>
                            </tr>
                            <tr>
                                <td>X</td>
                                <td>movement
                                    level is above intrigue threshold</td>
                            </tr>
                            <tr>
                                <td>L</td>
                                <td>left pir
                                    sensor triggered</td>
                            </tr>
                            <tr>
                                <td>R</td>
                                <td>right
                                    pir sensor triggered</td>
                            </tr>
                            <tr>
                                <td>P</td>
                                <td>front
                                    pir sensor triggered</td>
                            </tr>
                            <tr>
                                <td>H</td>
                                <td>microphone
                                    level is above noise threshold</td>
                            </tr>
                            <tr>
                                <td>Void</td>
                                <td>movement
                                    is below boredom threshold</td>
                            </tr>
                        </table>

                        <p>communication between the modules
                            uses microsoft component object module (com) which is the recommended method for
                            communicating between visual basic 6 components</p>
                    </div>
                    <h4 id="SoftwareVision">inkha software vision</h4>
                    <div className="techbody">
                        <p>
                            <b>motion-based tracking</b><br />
                            <a target="top" href="https://usesthis.com/interviews/bob.mottram/">bob mottram</a> provided free vb code that tracks moving objects<br />
                            his code was expanded upon and rejigged for our purposes
                        </p>

                        <p>
                            <b>thresholds</b><br />
                            when the value of a certain parameter is exceeded an event occurs<br />
                            once a certain parameter exceeds a threshold then activity or an action occurs<br />
                            example:<br />
                            the x position of the centre of motion creates lies in the e sector<br />
                            the activity is sufficient to frighten inkha<br />
                            the value of a certain number of pixels in the foveal region is deemed &#145;red' (rgb only)
                        </p>

                        <p>
                            <b>nature</b><br />
                            the activity of the human eye has been
                            intensively studied and modelled<br />
                            humans use saccades for rapid scanning, moving a small area of high resolution rapidly
                            around a visual scene<br />
                            a map of the saccadic movements of the eyes examining an image<br />

                            <img src="images/mapsaccade.jpg" width="384" height="233" alt="wpe5.jpg (10127 bytes)" /><br />

                            <small>dr daniel barth, mammalian neurophysiology, sensorimotor system ii: posture</small>
                        </p>

                        <p>
                            this has been modelled:<br />

                            <img src="images/controlsaccade.jpg" alt="" width="386" height="224" /><br />
                            <small>s f zornetzer, joel l davis, c lau (eds), an intro to neural and electronic networks, academic press inc, p195</small>
                        </p>

                        <p>
                            the purpose of these movements is to bring images of particular areas of the visual world to fall onto the fovea, saccades are
                            therefore a major instrument of selective visual attention<br />
                                rapid movement of the eyes and a slower head movement constitute a saccade
                        </p>

                        <p>
                            since the eyes are already on the target
                            as the head moves, a compensatory movement of the eyes relative to the head is required to
                            keep the eyes on the target the vestibular-occular reflex (vor) (which uses the ears&#146;
                            semi-circular canals to monitor head accelerations) is shut off for saccades of 40 degrees
                            or more
                        </p>

                        <p>the net result of this is constancy of gaze</p>

                        <p><b>the concept of colour, the concept of a &#145;shirt&#146;</b></p>

                        <p>the foveal region is used for colour analysis</p>

                        <p><b>using rgb:</b></p>
                        <p>
                            initial analysis of what constitutes typical &#145;red&#146; was undertaken using thresholds<br />
                            typically &#145;red&#146;, &#145;green&#146;or &#145;blue&#146; have an rgb value of <br />
                            &nbsp;&nbsp;&nbsp; 80% its &#145;own&#146; colour <br />
                            &nbsp;&nbsp;&nbsp; and less than 30% those of the other two colours<br />
                            black was defined as having all rgb values below 30%<br />
                            white all rgb values above 80%
                        </p>

                        <p>
                            the colour is then used to produce a verbal comment using the <a href="TechInkha#SoftwareSpeech">speech module</a>
                        </p>

                        <p>
                            it was soon noticed that the lighting levels and the camera compensation for this made
                            dynamic alteration of these percentage values very desirable
                        </p>

                        <p><b>using hsl:</b></p>
                        <p>
                            the hue is used to negate the problem of low light level<br />
                            hue is used in two ways (selected in <a href="TechInkha#behaviour">behavioural
                                module</a>): density and average<br />
                            the <b>density</b> check-box creates a count of the instances of the
                            integer values of hue (0 -359), the most frequent hue being selected<br />
                            the <b>average</b> check-box is the average of the hues<br />
                        </p>
                        <p>
                            the hue/colour that is seen is then compared to a colour database entry<br />
                            inkha then issues a verbal comment via the <a href="TechInkha#speech">speech module</a>
                        </p>

                        <p><b>what is a 'shirt'?</b></p>
                        <p>
                            initial qualitative experiments determined that activity centred on faces and large areas of colour<br />
                            further analysis defined the shirt concept as: &quot;the requirement to occupy 10% of the foveal region&quot;
                        </p>

                        <p><b>vector production</b></p>
                        <p>
                            to move inkha the sensing system had to provide appropriate vectors to initiate tracking movement<br />
                            it was decided that the following vectors would be used: n, nw, w, sw, s, se, e and c <br />
                            these are produced by processing the camera output<br />
                            the l and r vectors will come from the &#145;ears&#146; (pirs)<br /><br />
                            <img src="images/vectorscreen.jpg" alt="wpe6.jpg (5277 bytes)" width="257" height="163" /><br />
                            here is a screenshot of the vectors&#146;relationship to each other<br />
                            the division of the visual region into a grid will produce these vectors<br />
                            the boundaries of the sectors will be dynamically alterable, allows flexibility to tune tracking to the idiosyncrasies of the motors or mechanics
                        </p>

                        <p>
                            to achieve lifelike behaviour the following vectors would be required: x, void and f <br />
                            these vectors are produced via the processing of the camera output<br />
                            they are used to create behaviours<br />
                            if the activity is not sufficiently high then a nothing of interest, noi, or void vector is produced, should the visual stimulus be intriguing then an x is produced and if the
                            activity is large then the f vector will indicate a frightening situation
                        </p>

                        <p>
                            this is analogous to the concept of the affordance of size: detection of a large creatures may cause the viewer to<br />
                            consider &#145;flight&#146;, whereas a smaller object may initialise the &#145;feed&#146; behaviour
                        </p>
                    </div>
                    <h4 id="SoftwareControl">inkha software control</h4>
                    <div className="techbody">
                        <p><b>control system principles</b></p>

                        <p>the following principles were
                            developed for the control system in order to achieve lifelike movement whilst keeping the
                            software simple:</p>

                        <ul>
                            <li>the system will not be aware
                                of world co-ordinates<br />
                                it will exhibit proprioception i.e. it will know where it is in relation to
                                itself but its only knowledge of the world will be through the image that is viewed by the
                                camera</li>
                            <li>tracking of an object will
                                move the eyes alone<br />
                                a second layer of control will trap movement of the eyes past their extremes
                                and convert it to neck movement. this principle clearly mimics human behaviour and is
                                termed &quot;co-operation&quot;</li>
                            <li>time-based code will attempt
                                to centre the eyes and compensate with neck movements once the object being tracked is
                                centred or when there is nothing of interest to view<br />
                                this is termed &quot;relaxation&quot; and mimics vestibular occular reflex
                                as described in the <a href="TechInkha#SoftwareVision">vision</a>&nbsp;section</li>
                            <li>periodic behaviour such as
                                blinking, boredom and colour recognition will be time- based and will work independently
                                of the tracking system</li>
                            <li>lifelike behaviour such as
                                fright and interest will be aperiodic and will be dependent upon sensory stimulation</li>
                        </ul>

                        <p>an inner loop in the control
                            module implements simultaneous movement of servos and speed restrictions on servos using
                            the following logic which is written here as pseudo-code:
                        </p>

                        <p>Find Distance to be moved by each
                            servo<br />
                            Find Maximum Distance to be moved by any servo<br />
                            For each servo<br />
                            &nbsp;&nbsp;&nbsp; Timedelay = Timedelay for this servo * Distance / Maximum
                            Distance<br />
                            Timedelay to use is the maximum of these Timedelays<br />
                            For Step = 1 to Maximum Distance step Granularity<br />
                            &nbsp;&nbsp;&nbsp; Move each servo to current position plus Step * Distance
                            / Maximum Distance<br />
                            &nbsp;&nbsp;&nbsp; Wait for Timedelay<br />
                            Move each servo to final position
                        </p>

                        <p>a single time-delay
                            parameter for each servo was found to be insufficient as different speeds are required for
                            different movements<br />
                            for example a fright movement needs to move the neck quickly whilst a
                            relaxation movement needs to move it slowly<br />
                            as a solution to this two sets of delays were implemented, one for fast
                            movement and one for slow movement&nbsp; the command module chooses between the two speeds
                            depending upon the vector being processed and passes the speed to the control module as a
                            parameter
                        </p>
                    </div>
                    <h4 id="SoftwareLogging">inkha software logging</h4>
                    <div className="techbody">
                        <p>we log in order to determine inkha's tracking and
                            motor movements (see debug-level)<br />
                            we used this to:<br />
                            produce the experimental plots<br />
                            check sequencing<br />
                            verify control system activity<br />
                            <br />
                            we also log activity and internal errors (see below)
                            <br />
                            <br />
                            <b>debug-level logging</b><br />
                            we set differing amounts of logging through the ui<br />
                            0 - off<br />
                            1 - intermediate level<br />
                            2 - detailed level<br />
                            <br />
                            with this we log:<br />
                            motor positions<br />
                            simulation positions<br />
                            control system commands (settling, sampling)<br />
                            visual activity level<br />
                            vectors produced<br />
                            <br />
                            <b>activity logging<br />
                            </b>we do this in order to analyse unattended activity<br />
                            <br />
                            with this we log:<br />
                            relay activity<br />
                            hue<br />
                            all speech<br />
                            use of touch screen buttons<br />
                            <br />
                            <b>error logging</b><br />
                            we do this to flag run-time software errors</p>
                    </div>
                    <h4 id="SoftwareSpeech">inkha software speech</h4>
                    <div className="techbody">
                        <p>the speech module converts
                            a speech requirement and a behaviour into actual speech<br />
                            it also allows voice
                            selection for text to speech functions</p>

                        <p>the speech module &nbsp;
                            interrogates a msaccess database to find the correct piece of speech for the required
                            circumstance<br />
                            this allows speech sets to be customised easily for a particular audience or language</p>

                        <p>the speech returned by the
                            database may be in the form of a wav file of audio which is replayed using standard ms
                            multimedia dll calls or a text string which is converted to speech using text to speech
                            technology</p>

                        <p>ms speech api (sapi)
                            version 5.1 was downloaded from the ms website and example programs were used as a basis
                            for the code</p>

                        <p>a comprehensive web
                            trawl was executed in an attempt to find a free or low-cost uk english voice<br />
                            no such voice could be found for sapi version 5.1 but a free lernout and
                            hauspie uk english voice was found for sapi version 4<br />
                            the speech system was downgraded to sapi version 4.0 so that this voice
                            could be used</p>

                        <p>the sapi5 version of the
                            module was superior in that it used a com control rather than an activex control which
                            means that an invisible form is not required
                            <br />
                            both sapi4 and sapi5
                            allow control over emphasis in the speech by use of embedded tags
                            <br /></p>
                    </div>
                    <h4 id="SoftwareModules">inkha software modules</h4>
                    <div className="techbody">
                        <p>
                            <a href="TechInkha#modular">modular design</a><br />
                            <a href="TechInkha#interface">interface</a><br />
                            <a href="TechInkha#sensor">sensor</a><br />
                            <a href="TechInkha#behaviour">behaviour</a><br />
                            <a href="TechInkha#command">command</a><br />
                            <a href="TechInkha#control">control</a><br />
                            <a href="TechInkha#speech">speech</a><br />
                            <a href="TechInkha#simulation">simulation</a><br />
                        </p>

                        <p id="control"><b>modular design</b></p>

                        <p>a modular design was chosen
                            in order to isolate code functionality into logical building blocks<br />
                            the design used a tree structure with top level executable programs
                            displaying the user interface and lower level modules providing functionality as required</p>

                        <p>error trapping was used at
                            all levels to log any errors to a log file</p>


                        <p><img src="images/textsoftwaremodules.gif" alt="" /></p>

                        <p id="interface"><b>interface module</b></p>

                        <p>this module is the executable that
                            is run to start the program</p>

                        <p>it is the only control system
                            module that has a user interface<br />
                            it displays the results of simulation and allows servo limits, servo speed information and
                            any other parameters required by the other control system modules to be set and recorded<br />
                            experience of unattended installations of inkha have shown that any user interface
                            parameter than can be changed will be changed so childproofing was added to the interface
                            to disable all parameter setting unless a password is entered</p>

                        <p>a timer driven loop within the
                            interface module calls the sensor module to read the camera, PIR and microphone statuses<br />
                            a second timer driven loop interrogates the sensor module periodically in order to
                            retrieve a vector indicating the direction in which the head assembly should move and a
                            colour which it should report<br />
                            should the cameras have moved recently then they are not re-sampled until the motors have
                            stopped moving to allow the&nbsp; image to settle: the asc16 servo controller notifies the
                            control software when motor movement is complete whereas the ssc servo controller must use
                            a time delay set from the user interface<br />
                            there is no need for the cameras to settle before time based actions such as relaxation
                            occur as these actions are not vision dependent </p>

                        <p>the algorithm required, defined in
                            pseudo code, is as follows:</p>

                        <p>If Time &#150; LastPIRMicrophoneTime &gt;
                            ColourTime<br />
                            &nbsp;&nbsp;&nbsp; Vector = PIR or microphone vector<br />
                            &nbsp;&nbsp;&nbsp; LastPIRMicrophoneTime = Time<br />
                            If camera is not moving<br />
                            &nbsp;&nbsp;&nbsp; Vector = read vector from sensor module<br />
                            If Time &#150; LastColourReadTime &gt; ColourTime<br />
                            &nbsp;&nbsp;&nbsp; Colour = read colour from sensor module<br />
                            &nbsp;&nbsp;&nbsp; LastColourReadTime = Time<br />
                            ProcessVector (Vector)<br />
                            DoTimedActions<br />
                            <br />
                            for some commissions a touch screen is used and the interface module calls into
                            the speech module to give directions, what's on information etc. when these buttons are
                            pressed</p>

                        <p id="sensor"><b>sensor module</b></p>

                        <p>this module integrates the
                            cameras, pirs and microphone and displays the user interface for them<br />
                            it allows parameters and thresholds for motion detection and colour recognition to be set
                            and displayed<br />
                            a mirror image of the image received by the camera is displayed as are images showing
                            detected motion and areas of attention, pir indicators and a microphone level <br />
                            <br />
                            the sensor module is called periodically by the interface module<br />
                            it passes back a direction vector to indicate the direction in which the control system
                            needs to move the head and a colour which is interpreted by the behaviour module resulting
                            in a statement issued using the speech module</p>

                        <p>information about how the sensor
                            module detects movement and colour can be found in the <a href="TechInkha#SoftwareVision">vision</a>
                            &nbsp;section</p>
                        <p id="behaviour"><b>behaviour module</b>
                            <br />
                            this module modifes sensory input according to user settings (selected behaviour)<br />
                            it alters inkha's responses to sensory input<br />
                            behaviours are: <br />
                            activity: nonchalant, skittishness (interprets interest and fright responses)<br />
                            verbal: charming, tetchy, obnoxious (select speech-set from database in response to
                            activity)<br />
                            mindset: astrological, fashion, factual (selects speech-set for the hue in response to
                            colour)<br />
                            colour: density, average (alters colour recognition algorithm)</p>

                        <p>modifying vectors:<br />
                            a direction vector passed from the sensor module is modified before it is sent on to the
                            command module<br />
                            for example if behaviour is nonchalant then a &quot;high level of interest at centre&quot;
                            vector passed from the interface module is converted to a &quot;low level of interest at
                            centre&quot; vector before it is passed to the command module<br />
                            <br />
                            modifying colour interpretation:<br />
                            a hue is determined by the sensor module<br />
                            for example if behaviour is mystic then any given hue will produce a verbal comment
                            related to astrology</p>

                        <p id="command"><b>command module</b></p>

                        <p>this module produces instructions
                            for moving the head assembly in a lifelike way according to the passed-in command vectors
                            and its co-operation rules<br />
                            it also caters for time based movements such as relaxation (centring of the eyes),
                            blinking and boredom, the period of which can all be controlled from the user interface</p>

                        <p>after experience of unattended
                            installations logic was added to allow control of a relay that inkha's motors can be
                            turned off whenever sleep mode is entered<br />
                            this allows the motors to rest during unattended installation whenever there are no
                            visitors<br />
                            furthermore a working hours facility was added so that motors can be rested for given
                            hours of the day</p>

                        <p>for each non-central
                            vector received the target eye position is incremented by one tenth of its total movement
                            in the direction of the vector<br />
                            the percentage was determined by experimentation<br />
                            co-operation logic is used to move other axes and to limit the eye movement
                            as required</p>

                        <p>next, any relaxation is
                            applied to the target positions before they are sent to the control module</p>

                        <p>once the desired position of the
                            head assembly has been determined by the command module flow passes to: </p>

                        <ul>
                            <li>the control module so that the head
                                can be moved </li>
                            <li>the simulation module so that the
                                desired position of the head assembly can be simulated. </li>
                            <li>the speech module if speech is
                                required</li>
                        </ul>

                        <p>this module uses a normalised
                            co-ordinate system with a value of zero representing any movement at its lowest or most
                            anticlockwise position and a value of one representing any movement at its highest or most
                            clockwise position</p>

                        <p>colour recognition, boredom
                            and blinking occur periodically</p>

                        <p>for some commissions the
                            command module also checks databases and the internet periodically for other information</p>

                        <p>the following rules were designed
                            for blinking: </p>

                        <ul>
                            <li>within each blinking period there
                                will be a chance one in two that a blink will happen</li>
                            <li>if a blink does happen there will
                                be a chance one in four that there will be a second blink immediately</li>
                        </ul>

                        <p id="control"><b>control module</b></p>

                        <p>this module takes the target
                            normalised position of each head assembly servo and converts it to the raw servo values
                            that need to be sent using the limit parameters that are passed in from the interface
                            module</p>

                        <p>it was implemented in such a way
                            as to allow multiple servos to move simultaneously<br />
                            it is also possible to control the maximum speed of any servo<br />
                            more information can be found in the <a href="TechInkha#SoftwareControl">control</a> section</p>

                        <p id="speech"><b>speech module</b></p>

                        <p>this module uses comment types
                            passed in from any module and parameters provided by the behaviour module to produce
                            speech<br />
                            for example the command module may instruct the speech module to produce speech for the
                            fourth time that it is bored and the behaviour module will add to this that the speech
                            should be obnoxious</p>

                        <p>this module also supplies a list
                            of available voices in a format suitable for display in a list box and allows the active
                            voice to be selected</p>

                        <p>information about how speech is
                            produced can be found in the <a href="TechInkha#SoftwareSpeech">speech</a> section</p>

                        <p id="simulation"><b>simulation module</b></p>

                        <p>this module receives positions of
                            the various head assembly axes in normalised co-ordinates and produces a three-dimensional
                            scaled graphical simulation of it<br />
                            the simulation can be displayed by the interface module and used to plot trajectories</p>

                        <p>accurate measurements of the
                            size and position of the key elements of the head assembly were taken and stored in
                            constants in the simulation module so that they can be changed easily in future if the
                            mechanism changes<br />
                            measurements were also taken of the limits of rotation of each axis</p>

                        <p>from these measurements a
                            number of simulation primitives were constructed using a series of straight lines between
                            sampled or geometrically created points</p>

                        <p>the origins of each centre of
                            rotation of the elements were calculated relative to the zero origin at the base of the
                            neck<br />
                            the sequence of transformations of each element around each origin was
                            analysed and the transformations were effected for each element using a series of
                            homogeneous co-ordinate transformations</p>

                        <p>after the last transformation
                            the x and y co-ordinates of the resulting transformed straight lines had a scale and
                            offset factor applied to map them to screen co-ordinates and the lines were then plotted
                            on screen using visual basic line drawing routines</p>
                    </div>
                    <hr id="Hardware"  />
                    <h3>inkha hardware</h3>
                    <div className="wash" style={{
                        backgroundImage: `url("images/AUTOTALK.GIF")`
                    }}>
                        <ul>
                            <li><a href="TechInkha#HardwareEyes">eyes</a></li>
                            <li><a href="TechInkha#HardwareEars">ears</a></li>
                            <li><a href="TechInkha#HardwareMouth">mouth</a></li>
                            <li><a href="TechInkha#HardwareElectronics">electronics</a></li>
                            <li><a href="TechInkha#HardwareNeck">neck</a></li>
                        </ul>
                    </div>
                    <h4 id="HardwareEyes">inkha hardware eyes</h4>
                    <div className="techbody">
                        <p><b>mechanism</b></p>

                        <p>the eye mechanism with five degrees of
                            freedom was purchased as a complete unit from androidworld.com in texas, usa</p>

                        <p>the eye mechanism contains two supercircuits
                            pc-53-xp cameras<br />
                            these cameras output in ntsc format which is converted to usb input via two
                            trust usb audio video editors from habitek</p>

                        <p><b>movement</b></p>

                        <p>eyelid and eye movement was measured on a
                            human so that the limits of mechanical movement of the assembly could be compared with
                            human movement and so that control system limits could be set<br />
                            results were as follows:</p>

                        <table>
                            <tr>
                                <td><b>eyelid position</b></td>
                                <td><b>angle (degrees)</b></td>
                            </tr>
                            <tr>
                                <td>at rest</td>
                                <td >30</td>
                            </tr>
                            <tr>
                                <td>fully
                                    open</td>
                                <td >50</td>
                            </tr>
                            <tr>
                                <td>fully closed</td>
                                <td >-20</td>
                            </tr>
                        </table>

                        <table>
                            <tr>
                                <td><b>eye movement</b></td>
                                <td><b>angle (degrees)</b></td>
                            </tr>
                            <tr>
                                <td>pitch</td>
                                <td >± 35</td>
                            </tr>
                            <tr>
                                <td>roll</td>
                                <td >± 5</td>
                            </tr>
                            <tr>
                                <td>yaw</td>
                                <td >± 50</td>
                            </tr>
                        </table>

                        <p><b>blinking</b></p>

                        <p>blinking in humans was observed<br />
                            rules were very complex to obtain as observations showed that humans blink
                            rate depends upon personality, tiredness and the immediate situation, e.g. humans blink
                            more when conversing<br />
                            furthermore blinks are not regular - double blinks and missed blinks can
                            often be observed</p>

                        <p><b>irises</b></p>

                        <p>during human conversation the eyes are a
                            central element of communication<br />
                            it was important that inkha had realistic eyes in order to encourage
                            interaction<br />
                            the eye assembly was supplied with painted on irises </p>

                        <div className="w98">
                            <p><img src="images/originaleye.jpg" alt="" /><br />
                                    <small>original iris</small></p>
                        </div>

                        <p>the ocular prosthetics department at
                            moorfields eye hospital in london was approached for advice and they recommended hand
                            painted realistic cornea units from orbital prosthetics</p>

                        <p>the prosthetic irises were delivered with
                            plastic stalks attached<br />
                            king&#146;s college workshop used a lathe to cut a 3mm hole through the
                            centre of the irises to remove the stalks and provide a clear window for the cameras<br />
                            on fitting to the eyes it was found that much of the field of view of the
                            cameras was obscured<br />
                            after several attempts at widening the holes with a hand held reamer they
                            were taken back to the workshop to be drilled out to 45 degrees and polished<br />
                            this gives no obstruction to the cameras and a reasonable aesthetic</p>

                        <div className="w114">
                        <p><img src="images/eye3mm.gif" alt="" /><br />
                                <small>iris with 3mm hole</small></p>
                        </div>

                        <div className="w75">
                        <p><img src="images/eyefinal.gif" />
                            <br />
                            <small>final 45 degree iris</small>
                        </p>
                        </div>

                        <p><b>eyelids</b></p>

                        <p>in order to block the cameras when the
                            eyelids are lowered and in order to improve the aesthetics of the face various designs
                            were made for eyelids<br />
                            experiments were made with rubber cut from balloons but these were
                            unsuccessful<br />
                            neal scanlan at neal scanlan animatronics studios in london was approached
                            for advice and he gave full instructions on how to make eyelids from layers of vulcanised
                            latex rubber built up in a mould<br />
                            eyelids were constructed and attached to inkha in October 2002
                        </p>

                        <div className="w253">
                            <img src="images/Liddetail.jpg" alt="LIDS.JPG (18077 bytes)" />
                        </div>
                        <p><small>inkha with vulcanised latex rubber eyelids</small></p>
                    </div>
                    <h4 id="HardwareEars">inkha hardware ears</h4>
                    <div className="techbody">
                        <p>
                            inkha is designed to detect and interact with human subjects<br />
                            inkha's field of view is approximately 60 degrees which means that human movement outside the occular fov cannot be detected<br />
                            a pyroelectric infra-red sensor was scavenged from a burglar alarm and mounted to the side of the head<br />
                            in inkha1 this sensor was connected but not used due to there being no easy way to interface it
                        </p>

                        <p>
                            for inkha2 3 yd85g mini pirs were bought from maplin which work at 6V <br />
                            these were interfaced via one of the inputs on the asc16 board<br />
                            as each ear sensor operates over 90 degrees, the sensing range for inkha is 90+90+60=240 degrees<br />
                            the pirs in inkha2 are used to wake inkha up from sleep <br />
                            when inkha2 is awake the left and right pirs cause a rapid movement to the side as if a sound had been heard
                        </p>
                    </div>
                    <h4 id="HardwareMouth">inkha hardware mouth</h4>
                    <div className="techbody">
                        <p>
                            a mouth mechanism was taken from a novelty radio<br />
                            <div className="w320">
                                <img src="images/mouthradio.jpg" width="320" alt="" />
                            </div>
                        </p>

                        <p>
                            a feather servomotor was used to replace the dc motor that the radio used to drive the mouth movement
                        </p>
                    </div>
                    <h4 id="HardwareElectronics">inkha hardware electronics</h4>
                    <div className="techbody">
                        <p><b>current requirements</b></p>

                        <p>current requirements of the hitec servos chosen for neck
                            movement and mouth were found from manufacturer&#146;s data sheets (see <a href="TechInkha#DataSheets">data sheets</a> section)</p>

                        <p>current requirements of the cirrus servos supplied with the
                            eye assembly could not be found from the manufacturer and were estimated from a hitec
                            servo with similar torque output</p>

                        <p>this gave total servo current requirements as follows:</p>

                        <table>
                            <tr>
                                <td ><b>Servo</b></td>
                                <td ><b>Current (mA)</b></td>
                                <td ><b>Voltage (v)</b></td>
                            </tr>
                            <tr>
                                <td><b>eye pitch</b></td>
                                <td>280</td>
                                <td>4.8 &#150; 6</td>
                            </tr>
                            <tr>
                                <td><b>left eye
                                    yaw</b></td>
                                <td>280</td>
                                <td>4.8 &#150; 6</td>
                            </tr>
                            <tr>
                                <td><b>right eye
                                    yaw</b></td>
                                <td>280</td>
                                <td>4.8 &#150; 6</td>
                            </tr>
                            <tr>
                                <td><b>left eyelid</b></td>
                                <td>280</td>
                                <td>4.8 - 6</td>
                            </tr>
                            <tr>
                                <td><b>right
                                    eyelid</b></td>
                                <td>280</td>
                                <td>4.8 - 6</td>
                            </tr>
                            <tr>
                                <td><b>neck yaw</b></td>
                                <td>700</td>
                                <td>4.8 - 6</td>
                            </tr>
                            <tr>
                                <td><b>neck top
                                    pitch</b></td>
                                <td>300</td>
                                <td>4.8 - 6</td>
                            </tr>
                            <tr>
                                <td><b>neck bottom
                                    pitch</b></td>
                                <td>300</td>
                                <td>4.8 - 6</td>
                            </tr>
                            <tr>
                                <td><b>mouth</b></td>
                                <td>180</td>
                                <td>4.8 - 6</td>
                            </tr>
                            <tr>
                                <td><b>total</b></td>
                                <td><b>2880</b></td>
                                <td>&nbsp;</td>
                            </tr>
                        </table>

                        <p>on request seetron quoted a 4 amp
                            rating for the mini ssc servo controller board</p>

                        <p>current requirements for other electrical components were
                            as follows:</p>

                        <table>
                            <tr>
                                <td ><b>component</b></td>
                                <td ><b>current (ma)</b></td>
                                <td><b>voltage (v)</b></td>
                            </tr>
                            <tr>
                                <td><b>mini ssc</b></td>
                                <td>10</td>
                                <td>7 - 15</td>
                            </tr>
                            <tr>
                                <td><b>autotalk</b></td>
                                <td>20</td>
                                <td>5 - 6</td>
                            </tr>
                            <tr>
                                <td><b>camera (2)</b></td>
                                <td>100</td>
                                <td>6 - 12</td>
                            </tr>
                            <tr>
                                <td><b>pir (2)</b></td>
                                <td>24</td>
                                <td>7.5 - 12</td>
                            </tr>
                            <tr>
                                <td><b>total</b></td>
                                <td><b>154</b></td>
                                <td>&nbsp;</td>
                            </tr>
                        </table>

                        <p><b><b>interference</b></b></p>

                        <p>initial tests running the eye mechanism and the cameras
                            concurrently showed that servo movement caused interference on the camera signal<br />
                            experiments showed that using a secondary power supply for the cameras avoided this
                            problem<br />
                            although the cameras are specified as requiring 12v they run perfectly well at voltages
                            down to 7.5v<br />
                            this also allowed one power supply to be run at 6v for the servos and and one at 7.5v for
                            the cameras and all other components</p>

                        <p><b><b>power supplies</b></b></p>

                        <p>the maximum theoretical current requirement for the servos
                            calculated above is 2.9a<br />
                            in practice the servos are not powered simultaneously so the power supply only has to be
                            large enough to power the largest servo with a safety margin<br />
                            following recommendations in seetron user manual two 1.2a linear regulated power supplies
                            were purchased from maplin, one running at 6v for the servos and one at 7.5v for the other
                            electronic components</p>

                        <p><b><b>autotalk board</b></b></p>

                        <p>the mini ssc servo controller supplied with the eye
                            assembly had three spare servo outputs which were required to run the neck servos<br />
                            to avoid the need for a second servo controller an autotalk board was purchased from
                            milford instruments which drives a servo motor in synchronisation with audio input<br />
                            a data sheet for the autotalk board can be found in the <a href="TechInkha#DataSheets">data
                                sheets</a> section<br />
                            one disadvantage of this board is that it cuts out any audio output from the computer so a
                            set of dedicated amplified speakers was also bought from maplin<br />
                            secondly the gain was insufficient to move the featherservo the required range</p>

                        <p><b><b>interface box</b></b></p>

                        <p>the interface box was designed to use
                            different connectors for each unique signal to avoid any ambiguity when connecting to it<br />
                            a schematic of the connections between the sockets is as follows:</p>

                        <div className="w375">
                            <p><img src="images/boxconnections.gif" alt="" /></p>
                        </div>

                        <p><b><b>relay box</b></b></p>

                        <p>the relay box is used optionally for
                            unattended running<br />
                            it uses a card from <a href="http://www.audon.co.uk" target="top">audon technologies</a>&nbsp;
                            which allows relays to be controlled from the parallel port<br />
                            one of these relays is wired in series with the low voltage side of the motor power supply
                            unit<br />
                            the effect of this is that inkha's motors can be turned off under program control<br />
                            the control system turns the motors off whenever inkha goes to sleep which saves them from
                            burnout caused by continuous running</p>


                        <p>there are some security issues involved with
                            controlling a parallel port under windows nt and later and a windows driver and code from <a href="http://www.sstnet.com" target="top">sst</a> was used to overcome this<br /></p>
                    </div>
                    <h4 id="HardwareNeck">inkha hardware neck</h4>
                        <div className="techbody">
                            <p><b>dimensions</b></p>

                            <p>the androidworld.com eye assembly uses
                                full-scale dimensions for the eyeball size and separation<br />
                                hence, full-scale dimensions were used for all components of the assembly</p>

                            <p><b>measurement</b></p>

                            <p>measurements were made of a human neck
                                movement in order to design the neck assembly:</p>

                            <table>
                                <tr>
                                    <td><b>neck movement</b></td>
                                    <td><b>angle (degrees)</b></td>
                                </tr>
                                <tr>
                                    <td>top
                                        yaw</td>
                                    <td>&plusmn; 35</td>
                                </tr>
                                <tr>
                                    <td>bottom
                                        yaw</td>
                                    <td>&plusmn; 35</td>
                                </tr>
                                <tr>
                                    <td>top
                                        tilt</td>
                                    <td>&plusmn; 30</td>
                                </tr>
                                <tr>
                                    <td>bottom
                                        tilt</td>
                                    <td>&plusmn; 30</td>
                                </tr>
                            </table>

                            <p><b>motors</b></p>

                            <p>in order to match combined top and bottom
                                human neck yaw movement to the measured angles above a specialist hitec servomotor with a
                                rotation of 140 degrees was specified for neck yaw movement</p>

                            <p>hitec servomotors with a torque of 4.8 Kg/cm
                                were specified to produce pitch movement in the top and bottom neck bearings <br />
                                an initial design was made with these motors driving the bearings directly
                                but, following torque calculations (see below) and test implementation, it was found that
                                the motors were of insufficient power to support this method&nbsp; a four bar mechanism
                                was specified to gear the movement</p>

                            <p><b>torque</b></p>

                            <p>in order to find the torque that would be
                                present at the two neck pitch joints:<br />
                                the eye mechanism was weighed<br />
                                the weight of the upper neck mechanism was estimated <br />
                                and the distance of the centre of the eye mechanism from the centres of
                                rotation was measured</p>

                            <div className="w328">
                                <img src="images/Weighing.gif" alt="" /><br />
                            </div>
                            <p><small>weighing the eye mechanism</small></p>

                            <p>using these measurements the torque present
                                at the top and bottom neck bearings was calculated:</p>

                            <table>
                                <tr>
                                    <td><b>component</b></td>
                                    <td><b>weight (kg)</b></td>
                                    <td><b>distance (cm)</b></td>
                                    <td><b>torque (kg/cm)</b></td>
                                </tr>
                                <tr>
                                    <td>eye
                                        assembly</td>
                                    <td>0.3</td>
                                    <td>10</td>
                                    <td>3</td>
                                </tr>
                            </table>

                            <table>
                                <tr>
                                    <td><b>component</b></td>
                                    <td><b>weight (kg)</b></td>
                                    <td><b>distance (cm)</b></td>
                                    <td><b>torque (kg/cm)</b></td>
                                </tr>
                                <tr>
                                    <td>neck
                                        top</td>
                                    <td>0.2</td>
                                    <td>7.5</td>
                                    <td>1.5</td>
                                </tr>
                                <tr>
                                    <td>eye
                                        assembly</td>
                                    <td>0.3</td>
                                    <td>17.5</td>
                                    <td>5.25</td>
                                </tr>
                                <tr>
                                    <td>total</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>6.75</td>
                                </tr>
                            </table>

                            <p><b>four bar mechanism</b></p>

                            <p>this mechanism was designed
                                using a computer aided design package to give a gearing ration of 145:60 or 2.4:1 </p>

                            <div className="w328">
                                <img src="images/4bar.gif" alt="" /><br />
                            </div>
                            <p><small>four-bar assembly design for neck gearing</small></p>

                            <p><b>materials</b></p>

                            <p>the principle of the mechanical design was to
                                use as many off-the-shelf components as possible<br />
                                where components had to be constructed they were designed to be fabricated
                                from readily available material stock</p>

                            <p>all dimensions of designed mechanical parts
                                matched real sized head measurements described above</p>

                            <p>a number of designs were made and evaluated
                                with the constraints being: </p>

                            <ul>
                                <li>the existing eye mechanism</li>
                                <li>life-sized dimensions
                                </li>
                                <li>aesthetics </li>
                                <li>minimising cost
                                </li>
                                <li>minimising complexity
                                </li>
                                <li>minimising weight </li>
                            </ul>

                            <p><b>components</b></p>

                            <p>bearing housings, neck and jaw components
                                were designed by drawing the entire head assembly and detailed sub-assemblies to scale
                                using a 2-d cad package<br />
                                detail drawings of required components were taken from the assembly drawings</p>
                    </div>
                    <hr id="Experiments" />
                    <h3>inkha experiments</h3>
                    <div className="wash" style={{
                        backgroundImage: `url("images/weigh.gif")`
                    }}>
                        <ul>
                            <li><a href="TechInkha#ExperimentsTrackXY">track x-y</a></li>
                            <li><a href="TechInkha#ExperimentsWorkspace">workspace</a></li>
                            <li><a href="TechInkha#ExperimentsShadowing">shadowing</a></li>
                            <li><a href="TechInkha#ExperimentsColour">colour</a></li>
                            <li><a href="TechInkha#ExperimentsTrackY">track y</a></li>
                            <li><a href="TechInkha#ExperimentsActivity">activity</a></li>
                        </ul>
                    </div>
                    <h4 id="ExperimentsTrackXY">inkha experiments trackxy</h4>
                    <div className="techbody">
                        <p>
                            <img src="images/trackxy.gif" alt="" /><br />

                            <b>below is a detailed description of the movement</b><br />

                            1- shown are the small movements of the eyes in an attempt to centralise the object of interest<br />
                            2- eyes have passed the relax limit: a large neck movement used to centralise the eyes in their sockets (relaxation) is then commanded and unfortunately
                            causing overshoot<br />
                            3 - eyes attempt to correct overshoot, attempt to centralise object<br />
                            4- timer requests relaxation, a smaller neck movement is needed to correct a
                            smaller eye offset and the eyes are now back at central pitch, laterally centred<br />
                            5 - since the eyes are pointing upwards the top neck pitch servo is then
                            used to compensate for this (relaxation) as the eyes are centred vertically, the net
                            effect is that the assembly moves forwards, or out of the plane of the paper
                        </p>
                    </div>
                    <h4 id="ExperimentsWorkspace">inkha experiments workspace</h4>
                    <div className="techbody">
                        <p>
                            <img src="images/workspace.gif" alt="" />
                        </p>
                    </div>
                    <h4 id="ExperimentsShadowing">inkha experiments shadowing</h4>
                    <div className="techbody">
                        <p>
                            1- initial position black object is stationary and red object is moving<br />

                            <img src="images/shadow1.gif" alt="" /><br />

                            it was possible to attract inkha&#146;s attention for a period of time by
                            vigorous waving but tracking often moved in the wrong direction<br />
                            this was caused by the shadow effect of background objects when the camera
                            moved
                        </p>

                        <p>
                            2 - camera moves towards red moving target<br />

                            <img src="images/shadow2.gif" alt="" /><br />
                        </p>

                        <p>
                            3 - when the camera compares
                            previous and current frames to detect movement the movement (shown here in yellow) of the
                            stationary object appears greater than the movement of the target so inkha turns back
                            towards the stationary target<br />

                            <img src="images/shadow3.gif" alt="" />
                        </p>

                        <p>
                            this problem is overcome by settle time, which was added after these experiments were made<br />

                            attention was often drawn to fluorescent lights and monitor screens, which could be seen on the sensor module interface
                            to be sources of constant movement<br />

                            the boredom threshold was fixed at 50 at this time and boredom cycles never executed unless the cameras were turned off<br />

                            turning blinking on made tracking of a waving hand impossible
                        </p>
                    </div>
                    <h4 id="ExperimentsColour">inkha experiments colour</h4>
                    <div className="techbody">
                        <p>
                            here is a screenshot of
                            the result of exposing the visual system to a green stimulus<br />
                            note the absence of activity in the visual field<br />

                            <img src="images/colour1.gif" alt="" /><br />
                            <small>exposure to a green stimulus</small>
                        </p>

                        <p>
                            <img src="images/colour2.gif" alt="" /><br />
                            <small>exposure to a red stimulus</small>
                        </p>
                    </div>
                    <h4 id="ExperimentsTrackY">inkha experiments tracky</h4>
                    <p>
                        it is informative to monitor the movement of the right-eye via the simulation as inkha acquires the target<br />

                        this highlights the settling time of 1.25s, the amount of time the mechanism has to wait again before moving so as not
                        to read false camera images<br />

                        here is a plot in the y-z plane (looking side-on at inkha&#146;s cheek, x is constant) i.e. a cross-section:

                        <ul>
                            <li>inkha starts off positioned in
                                the bottom-right corner of the plot</li>
                            <li>inkha initially moves the eye
                                to attempt to centralise the object </li>
                            <li>after a period of time the
                                relaxation rule of the command module centres the eye and positions the head to compensate
                                (this mimics human eye movement where the eyes are always centred in their sockets where
                                possible to maximise depth of focus)</li>
                            <li>this process continues until
                                the target is acquired</li>
                        </ul>

                        <img src="images/tracky.gif" alt="" />

                    </p>
                    <div className="techbody">
                    </div>
                    <h4 id="ExperimentsActivity">inkha experiments activity</h4>
                    <div className="techbody">
                        <p>
                            a level of activity above the
                            boredom and above the intriguing threshold was produced, ensuring attention is central<br />

                            <img src="images/activity.gif" alt="" />
                        </p>

                        <p><small>x vector production experiment screenshot</small></p>

                        <p><b>raw data:</b></p>

                        <p>
                            2639675,vision,8,4,11,11,24<br />
                            2639715,vision,0.55,0.45,24,c not intriguing enough<br />
                            2639865,vision,8,3,12,12,32<br />
                            2640076,vision,8,5,12,12,29<br />
                            2640096,vision,0.55,0.45,29,x<br />
                            2640266,vision,8,3,12,13,36<br />
                            2640506,vision,0.55,0.45,36,x <br />
                            2640797,vision,0.55,0.45,36,x stop experiment
                        </p>
                    </div>
                    <hr id="DataSheets" />
                    <h3>inkha datasheets</h3>
                    <div className="blurb">
                        <p><b>eye assembly</b><br />
                            <a href="http://www.androidworld.com" target="top">http://www.androidworld.com</a></p>

                        <p><b>servo board inkha2</b><br />
                            <a href="http://www.acscontrol.com/Index_ACS.asp" target="top">http://www.acscontrol.com/Index_ACS.asp</a></p>
                        <p><b>servos inkha2</b><br />
                            <a href="http://www.volz-servos.com" target="top">http://www.volz-servos.com</a></p>
                        <p><b>servo board inkha1</b><br />
                            <a href="http://www.seetron.com/pdf/ssc.pdf" target="top">http://www.seetron.com/pdf/ssc.pdf</a></p>
                            <p><b>servos inkha1</b><br />
                            <a href="https://servodatabase.com/servos/cirrus" target="top">https://servodatabase.com/servos/cirrus</a> cirrus - eye assembly<br />
                            <a href="http://www.hitecrcd.com" target="top">http://www.hitecrcd.com</a> hs5245mg - neck pitch<br />
                            <a href="http://www.hitecrcd.com" target="top">http://www.hitecrcd.com</a> hs815 - neck yaw<br />
                            <a href="http://www.hitecrcd.com" target="top">http://www.hitecrcd.com</a> hs55 - neck mouth
                        </p>

                        <p><b>speech inkha1 - milford instruments autotalk board</b><br />
                            <a href="http://www.bpesolutions.com/productimages/animatronics/autotalk.html" target="top">http://www.bpesolutions.com/productimages/animatronics/autotalk.html</a></p>

                        <p><b>prosthetic irises - prosthetic shells</b><br />
                            <a href="http://www.orbitalprosthetic.com/" target="top">http://www.orbitalprosthetic.com/</a></p>
                    </div>
                </div >
            </div >
        )
    }
}

