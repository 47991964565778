import * as React from 'react';
import { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

export default class App extends Component {
    state = { isOpen: false, bigName: '' };

    handleShowDialog = (fname) => {
        if (fname == '')
            this.setState({ isOpen: false });
        else {
            this.setState({ isOpen: !this.state.isOpen });
            this.setState({ bigName: fname });
        }
        // console.log(fname);
    }

    render() {
        return (
            <div className="centralMainPage">
                {this.state.isOpen && (
                    <div className="backButton">
                        <img src="images/back.png" onClick={() => this.handleShowDialog('')} height="20px"></img>
                    </div>
                )}
                <div className="top">
                    <h2>plastic inkha images</h2>
                </div>
                {this.state.isOpen && (
                    <div className="blurb">
                    <img
                        className="image"
                        src={this.state.bigName}
                        onClick={this.handleShowDialog}
                        title="click to close"
                    />
                    </div>
                )}
                {!this.state.isOpen && (
                    <div className="blurb">
                        <table>
                            <tbody>
                                <tr>
                                    <td width="50%"><img src="jpegs/rpinkha1.jpg" onClick={() => this.handleShowDialog('jpegs/pinkha1.jpg')} title="click for a larger view"  /></td>
                                    <td width="50%"><img src="jpegs/rpinkha2.jpg" onClick={() => this.handleShowDialog('jpegs/pinkha2.jpg')} title="click for a larger view"  /></td>
                                </tr>
                                <tr>
                                    <td width="50%"><img src="jpegs/rpinkha3.jpg" onClick={() => this.handleShowDialog('jpegs/pinkha3.jpg')} title="click for a larger view"  /></td>
                                    <td width="50%"><img src="jpegs/rpinkha4.jpg" onClick={() => this.handleShowDialog('jpegs/pinkha4.jpg')} title="click for a larger view"  /></td>
                                </tr>
                                <tr>
                                    <td width="50%"><img src="jpegs/rpinkha5.jpg" onClick={() => this.handleShowDialog('jpegs/pinkha5.jpg')} title="click for a larger view"  /></td>
                                    <td width="50%"><img src="jpegs/rpinkha6.jpg" onClick={() => this.handleShowDialog('jpegs/pinkha6.jpg')} title="click for a larger view"  /></td>
                                </tr>
                                <tr>
                                    <td width="50%"><img src="jpegs/rpinkha7.jpg" onClick={() => this.handleShowDialog('jpegs/pinkha7.jpg')} title="click for a larger view"  /></td>
                                    <td width="50%"><img src="jpegs/rpinkha8.jpg" onClick={() => this.handleShowDialog('jpegs/pinkha8.jpg')} title="click for a larger view"  /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                </div>
        )
    }
}

//<ImageGallery items={images} />