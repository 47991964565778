//import { useState } from "react";
import * as React from 'react';
import { useEthers, useEtherBalance } from "@usedapp/core";
import { utils } from "ethers";

import { useCount, useContractMethod } from "../hooks";


export default function Gallery(props) {
    const { activateBrowserWallet, account } = useEthers();

    interface MintProps {
        ownedTokens: number[],
        handleClick: any
    }

    function getLink(id){
        return "https://inkha.s3.eu-west-2.amazonaws.com/imagesSmall/" + id.toString() + ".jpg";
    }

    function getDivs() {
    if (props.ownedTokens == null || props.ownedTokens.size == 0)    
    {
        return;
    }    
    return(
        <div className="galleryImages">
        {
                props.ownedTokens.map((j) => (
                    <img src={getLink(j)} onClick={() => props.handleClick(j)} />
            ))
                }
        </div>          
    );
    }

    //console.log(props.ownedTokens);

    const count = useCount();
    return (
        <div className="">

            {getDivs()}

        </div>

    );
}