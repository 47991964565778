import * as React from 'react';
import { Component } from 'react';
import { Link} from 'react-router-dom';

export default class App extends Component {
    render() {
        return (
            <div className="centralMainPage">

                <div className="top">
                    <h2>inkha videos</h2>
                </div>
                <div className="blurb">
                    <table>
                        <tbody>
                            <tr>
                                <td width="50%"><a href="video/InkhaShowreel1.mp4"><img src="jpegs/showreel.jpg" alt="click to see the showreel"  />
                                </a><br />showreel</td>
                                <td width="50%"><a href="video/InkhaFilm.mp4"><img src="jpegs/film.jpg" alt="click to see the film"  />
                                </a><br />film</td>
                            </tr>
                            <tr>
                                <td width="50%"><a href="video/Inkhabp.mp4"><img src="jpegs/inkhabp.jpg" alt="click to see the video"  />
                                </a><br />blue peter</td>
                                <td width="50%"><a href="video/Inkhatw.mp4"><img src="jpegs/inkhatw.jpg" alt="click to see the video"  />
                                </a><br />tomorrow's world</td>
                            </tr>
                            <tr>
                                <td width="50%"><a href="video/closeup.mp4"><img src="images/closeup.gif" alt="click to see the video"  />
                                </a><br />closeup</td>
                                <td width="50%"><a href="video/hallow.mp4"><img src="images/hallow.gif" alt="click to see the video"  />
                                </a><br />halloween</td>
                            </tr>
                            <tr>
                                <td width="50%"><a href="video/fastmove4.mp4"><img src="images/movement.gif" alt="click to see the video"  />
                                </a><br />movement</td>
                                <td width="50%"><a href="video/tracking.mp4"><img src="images/tracking.gif" alt="click to see the video"  />
                                </a><br />tracking</td>
                            </tr>
                            <tr>
                                <td width="50%"><a href="video/fullturn.mp4"><img src="images/turning.gif" alt="click to see the video"  />
                                </a><br />turning</td>
                                <td width="50%"><a href="video/blinking.mp4"><img src="images/blinking.gif" alt="click to see the video"  />
                                </a><br />blinking</td>
                            </tr>
                            <tr>
                                <td width="50%"><a href="video/colour1.mp4"><img src="images/colour.gif" alt="click to see the video"  />
                                </a><br />colour</td>
                                <td width="50%"><a href="video/bored3.mp4"><img src="images/boredom.gif" alt="click to see the video"  />
                                </a><br />boredom</td>
                            </tr>
                            </tbody>
                    </table>
                </div>
            </div>

        )
    }
}

