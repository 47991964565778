//import { useEthers, useEtherBalance } from "@usedapp/core";
//import { formatEther } from "@ethersproject/units";
//import { useState } from 'react';

//import Identicon from "./Identicon";
import * as React from 'react';
import ConnectButton from "./ConnectButton";
import AccountModal from "./AccountModal";
//import Count from "./MintButton";
//import theme from "../theme";

export default function WalletStuff(props) {


    return (

        <div>
            <div className="walletStuff">
                <ConnectButton handleOpenModal={props.setOpen} classString={props.classString}/>
                <AccountModal isOpen={props.isOpen} onClose={props.setOpen} classNme={props.classNme}/>
            </div>
        </div>

    );

}
