import * as React from 'react';


import { useEthers } from "@usedapp/core";

type Props = {
    isOpen: any;
    onClose: any;
    classNme: string;
};

export default function AccountModal({ isOpen, onClose,classNme }: Props) {
    const { account, deactivate } = useEthers();

    function handleDeactivateAccount() {
        deactivate();
        closeModal();
    }

    function closeModal() {
        onClose(false);
    }

    function getEtherscan() {
        return "https://goerli.etherscan.io/address/" + account;
    }

    return (
        (isOpen==true)?(
            <div className={classNme}>
                <button className="floatright closeButton" onClick={closeModal} type="button">x</button>

                <a href={getEtherscan()} target="none"><button onClick={handleDeactivateAccount}>etherscan</button></a>
                <br/>
                Connected with {account &&
                `${account.slice(0, 6)}...${account.slice(
                    account.length - 4,
                    account.length
                )}`}
                <br />

                <button onClick={handleDeactivateAccount}>logout</button>
                                   
            </div >
                )
:(
<div/>
)
    );
}
